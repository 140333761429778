<template>
  <div>
    <div class="card">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickSettingButton="onClickSettingButton"
          @onClickCloseButton="onClickCloseButton"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Supplier</label>
              <input type="text" class="form-control" :value="supplier" readonly>
            </div>
            <div v-if="!isPOCreate" class="col-sm-4">
              <label class="align-middle">PO No</label>
              <input type="text" class="form-control" :value="formData.po_number" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">PO Date</label>
              <input type="date" class="form-control date-mask" v-model="formData.po_date">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <label class="align-middle">Requisition Details:</label>
              <div class="mt-1">
                <template :key="index" v-for="(data, index) in formData.requisition_details">
                  Requisition No: <strong>{{ data.requisition_number }}</strong>, Requisition Date:
                  <strong>{{ data.requisition_date }}</strong>,
                  Requester Name: <strong>{{ data.requester_name }}</strong>, Designation: , Department: .
                  <br>
                </template>
              </div>
            </div>

            <div class="col-sm-12 mt-1">
              <label class="align-middle">CS/Quotation Details:</label>
              <div class="mt-1">
                <template :key="index" v-for="(data, index) in formData.cs_details">
                  CS/Quotation No: <strong>{{ data.cs_number }}</strong>, CS/Quotation Date: <strong>{{
                    data.cs_date
                  }}</strong>.
                  <br>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="px-2">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
          </li>
          <li @click="formData.has_item_detail = false" class="nav-item">
            <a :class="{'active' : !formData.has_item_detail}" class="nav-link"
               data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
          </li>
        </ul>
      </div>

      <div class="px-3">
        <div v-if="!formData.has_item_detail">
          <template :key="index" v-for="(data, index) in accountsDetails">
            <ItemDetails
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :currencies="currencies"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="false"
              :isPO="true"
              :isPOCreate="isPOCreate"
              @callConfirmLimit="getConfirmLimit"
            />
          </template>
        </div>

        <div v-else>
          <template :key="index" v-for="(data, index) in itemsDetails">
            <ItemDetails
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :currencies="currencies"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="true"
              :isPO="true"
              :isPOCreate="isPOCreate"
              @callConfirmLimit="getConfirmLimit"
            />
          </template>
        </div>
      </div>

      <div class="px-2 mt-3 mb-3">
        <div class="row mt-3 px-2">
          <div class="col-md-6 row justify-content-center">
          </div>

          <div class="col-md-6">
            <div class="row mt-1">
              <div class="col-md-4 align-middle">SubTotal :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="subTotal" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(+) VAT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="totalVAT" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">Total :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="total" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(-) Discount / Round up Adj :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model="formData.adjustment_amount"  >
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(+) Carrying Cost :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model="formData.carrying_cost"  >
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">(+) TDS :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model="formData.tds"  >
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">NET PAYABLE AMOUNT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" v-model.trim="grandTotal" readonly>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="mb-2">
              <label for="note" class="form-label fw-bold">Terms & Conditions:</label>
              <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2"
                        id="description"></textarea>
            </div>
          </div>
        </div>

        <div class="row mb-1 d-flex justify-content-end" v-if="(!poEditData.grn_general_count)">
          <div class="col-2">
            <label for="">&nbsp;</label>
            <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="savePO()"
              :disabled="!shouldSave"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
            >Save
            </button>
          </div>
          <div class="col-2" v-if="formData.status && formData.status !== 'pending'">
            <label for="">&nbsp;</label>
            <button
              @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)"
              class="btn btn-warning waves-effect waves-float waves-light form-control"
              style="min-width: 64px;margin-top: 1rem;"
            >
              Show More
            </button>
          </div>
        </div>
      </div>
    </div>

    <view-approval-matrix-details
      :workflowDetails="workflowDetails"
      :approvalLogs="formData.workflow_approval_logs ?? []"
      ref="viewApprovalMatrix"
    />
    <GlobalLoader/>
  </div>
</template>
<style>
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

.title-background {
  background: #DBE7F2
}
</style>

<script setup>
import TitleButton        from '@/components/atom/TitleButton'
import {
  inject,
  ref,
  onMounted,
  computed,
  watch
}                         from 'vue';
import {
  useRoute,
  useRouter
}                         from "vue-router";
import {useStore}         from 'vuex';
import BlobImageViewer    from '@/components/atom/BlobImageViewer'
import handleRequisitions from "@/services/modules/procurement/requisition";
import handlePurchase     from '@/services/modules/purchase'
import handleContact      from '@/services/modules/contact'
import ItemDetails        from '@/components/molecule/procurement/purchase-order/purchase-order-entry/ItemDetails.vue'
import {vatRate}          from '@/data/inventory.js'
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const router      = useRouter()
const route       = useRoute()
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const store       = useStore()

const {fetchProductList, fetchAccountHead, fetchCurrencyList, ...rest} = handlePurchase()
const {fetchSingleContactProfile}                   = handleContact()
const {fetchWorkflowFromPageId} = handleWorkflow()
const {
        fetchPONumber,
        fetchCSGeneralData,
        createNewPO,
        fetchSinglePO,
        updatePOItem,
        getCSAmountQuantityRemainingLimit
      }                                             = handleRequisitions();

const companyId   = computed(() => {
  return route.params.companyId
})
let headerTitle   = ref('Create PO')
let loader        = ref(false)
let productLoader = ref(false)
let disableSave   = ref(false)
let products      = ref([])
let currencies    = ref([])
let accountHeads  = ref([])
let requisition   = ref({})
let supplier      = ref(null)
let user          = ref(null)
let grandTotal = ref(null);
let adjustmentTotal = ref(null);

let poEditData    = ref({})
const workflowDetails = ref({});
const viewApprovalMatrix = ref(null);
let isPOCreate    = true
let poData        = {
  general: [],
}
let formData      = ref({
  supplier_id        : null,
  po_number          : null,
  po_date            : null,
  requisition_details: [],
  cs_details         : [],
  subtotal_amount    : 0.00,
  vat_amount         : 0.00,
  total_amount       : 0.00,
  has_item_detail    : true,
  description        : '',
  adjustment_amount: 0,
  carrying_cost: 0,
  tds: 0,
  item_details       : [
    {
      product_id  : null,
      currency    : null,
      quantity    : null,
      rate        : null,
      amount      : null,
      vat         : 0,
      vat_amount  : null,
      total_amount: null,
      description : null,
      discount_amount: 0,
      discount_percent: 0,
    }
  ],
  account_details    : [
    {
      account_head_id: null,
      currency       : null,
      amount         : null,
      vat            : 0,
      vat_amount     : null,
      total_amount   : null,
      description    : null,
    }
  ],
})

function onPageReload() {
  loader.value         = true
  const productRes     = fetchProductList(getQuery())
  const accountHeadRes = fetchAccountHead(getQuery())
  const currencyRes    = fetchCurrencyList(getQuery())
  const workflowQuery = getQuery() + `&approval_list_page_id=${route.params.pageId}`;

  Promise.all([
    productRes.then(res => {
      if (res.data) products.value = res.data
    }),
    currencyRes.then(res => {
        if (res.data) currencies.value = res.data
    }),
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data

      if (route.query.poId && route.query.poId !== '' && route.query.poId !== undefined) {
        prepareUpdatePageData();
      } else {
        prepareCreatePageData();
      }
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    })
  ])
      .then(() => {
        loader.value = false;
      })
      .catch((err) => {
        loader.value = false
      })
}

function prepareUpdatePageData(){
  isPOCreate    = false;
  const fetchPO = fetchSinglePO(route.query.poId, getQuery());
  fetchPO.then(res2 => {
    poEditData.value = res2.data;
    setFormData();
  });
}

function prepareCreatePageData(){
  const cs_general_items    = {
    cs_general_items: Array.isArray(route.query.selected_cs_item) ? route.query.selected_cs_item : [route.query.selected_cs_item]
  }
  // Fetch Supplier
  const fetchContactProfile = fetchSingleContactProfile(route.query.supplierId, getQuery());
  fetchContactProfile.then(res => {
    if (res.data) supplier.value = res.data.name;
  });
  // Generate PO number
  const poNumberRes = fetchPONumber(getQuery());
  poNumberRes.then(res => {
    formData.value.po_number = res;
  });
  // Fetch CS Generals
  const fetchCSGenerals = fetchCSGeneralData(cs_general_items, getQuery());
  fetchCSGenerals.then(res => {
    if (res.status) {
      let csgData = res.data;
      delete formData.value.item_details;
      delete formData.value.account_details;
      formData.value.item_details        = [];
      formData.value.account_details     = [];
      formData.value.requisition_details = [];
      formData.value.cs_details          = [];
      csgData.forEach((item) => {
        formData.value.requisition_details.push({
          requisition_number: item.cs_master.requisition_number,
          requisition_date  : new Date(item.cs_master.requisition_date).toISOString().split('T')[0],
          requester_name    : item.cs_master.user.name,
        });
        formData.value.cs_details.push({
          cs_number: item.cs_master.cs_number,
          cs_date  : new Date(item.cs_master.cs_date).toISOString().split('T')[0],
        });

        if (item.is_product == '1') {
          item.product_id = item.requisition_general.product_id;
          item.description = item.item_specification;
          item.quantity = 0;
          item.rate = item.rate_confirm;
          item.total_amount = 0;
          item.requisition_qty = item.requisition_general.quantity
          formData.value.item_details.push(item);
        } else {
          item.account_head_id = item.requisition_account.account_head_id;
          item.description = item.item_specification;
          item.amount = 0;
          item.total_amount = 0;
          formData.value.account_details.push(item);
        }
      })
      setFormData();
    }
  })
}

function setFormData() {
  if (isPOCreate) {
    formData.value.po_date     = new Date().toISOString().split('T')[0];
    formData.value.supplier_id = route.query.supplierId;
  } else {
    headerTitle.value          = 'Update PO';
    supplier.value             = poEditData.value.contact_profile.full_name;
    formData.value.supplier_id = poEditData.value.contact_profile_id;
    formData.value.po_date     = new Date(poEditData.value.po_date).toISOString().split('T')[0];
    formData.value.po_number   = poEditData.value.po_number;
    formData.value.description = poEditData.value.description;
    formData.value.workflow_approval_logs = poEditData.value.workflow_approval_logs;
    formData.value.status = poEditData.value.status;
    formData.value.adjustment_amount = poEditData.value.adjustment_amount;
    formData.value.carrying_cost = poEditData.value.carrying_cost;
    formData.value.tds = poEditData.value.tds;

    delete formData.value.item_details;
    delete formData.value.account_details;
    formData.value.item_details        = [];
    formData.value.account_details     = [];
    formData.value.requisition_details = [];
    formData.value.cs_details          = [];
    poEditData.value.purchase_general.forEach((item) => {
      formData.value.requisition_details.push({
        requisition_number: item.cs_general.cs_master.requisition_number,
        requisition_date  : new Date(item.cs_general.cs_master.requisition_date).toISOString().split('T')[0],
        requester_name    : item.cs_general.cs_master.user.name,
      });
      formData.value.cs_details.push({
        cs_number: item.cs_general.cs_master.cs_number,
        cs_date  : new Date(item.cs_general.cs_master.cs_date).toISOString().split('T')[0],
      });

      if (item.is_product == '1') {
        item.product_id = item.cs_general.requisition_general.product_id;
        item.quantity_confirm = item.cs_general.quantity_confirm;
        item.rate_confirm = item.cs_general.rate_confirm;
        item.requisition_qty = item.cs_general.requisition_general.quantity
        formData.value.item_details.push(item);
      } else {
        item.account_head_id = item.cs_general.requisition_account.account_head_id;
        item.rate_confirm = item.cs_general.rate_confirm;
        formData.value.account_details.push(item);
      }
    })
  }
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function onClickCloseButton() {
  if (isPOCreate) {
    router.push({name: `cs-quotation-approved-list`, params: route.params, query: route.query});
  } else {
    delete route.query.poId;
    router.push({name: `po-list`, params: route.params, query: route.query});
  }
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function savePO() {
  if (isPOCreate) {
    handlePOSubmit();
  } else {
    handleUpdatePO();
  }
}

function handlePOSubmit() {
  poData.company_id  = companyId.value;
  poData.supplier_id = formData.value.supplier_id;
  poData.po_number   = formData.value.po_number;
  poData.po_type     = "cs_po";
  poData.po_date     = formData.value.po_date;
  poData.description = formData.value.description;
  poData.adjustment_amount = formData.value.adjustment_amount;
  poData.carrying_cost = formData.value.carrying_cost;
  poData.tds = formData.value.tds;

  poData.general = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      let data           = {};
      data.supplier_id   = formData.value.supplier_id;
      data.cs_general_id = item.id;
      data.is_product    = item.is_product;
      data.po_number     = formData.value.po_number;
      data.quantity      = item.quantity;
      data.currency      = item.currency;
      data.rate          = item.rate;
      data.amount        = getAmount(item);
      data.vat           = item.vat;
      data.vat_amount    = getVatAmount(item, data.amount);
      data.discount_percent = item.discount_percent;
      data.discount_amount = item.discount_amount;
      data.total_amount  = getTotalAmount(item, data.amount, data.vat_amount,data.discount_amount);
      data.description   = item.description;

      poData.general.push(data);
    })
  }
  if (formData.value.account_details.length > 0) {
    formData.value.account_details.forEach((item) => {
      let data           = {};
      data.supplier_id   = formData.value.supplier_id;
      data.cs_general_id = item.id;
      data.is_product    = item.is_product;
      data.po_number     = formData.value.po_number;
      data.quantity      = 0;
      data.currency      = item.currency;
      data.rate          = 0.00;
      data.amount        = getAmount(item);
      data.vat           = item.vat;
      data.vat_amount    = getVatAmount(item, data.amount);
      data.total_amount  = getTotalAmount(item, data.amount, data.vat_amount);
      data.description   = item.description;

      poData.general.push(data);
    })
  }
  poData.general = JSON.stringify(poData.general);

  createPO();
}

function handleUpdatePO() {
  poData.company_id  = companyId.value;
  poData.supplier_id = formData.value.supplier_id;
  poData.po_date = formData.value.po_date;
  poData.description = formData.value.description;
  poData.adjustment_amount = formData.value.adjustment_amount;
  poData.carrying_cost = formData.value.carrying_cost;
  poData.tds = formData.value.tds;

  poData.general = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      let data          = {};
      data.id           = item.id;
      data.quantity     = item.quantity;
      data.currency     = item.currency;
      data.rate         = item.rate;
      data.amount       = getAmount(item);
      data.vat          = item.vat;
      data.vat_amount   = getVatAmount(item, data.amount);
      data.discount_percent = item.discount_percent;
      data.discount_amount = item.discount_amount;
      data.total_amount = getTotalAmount(item, data.amount, data.vat_amount,data.discount_amount);
      data.description  = item.description;

      poData.general.push(data);
    })
  }
  if (formData.value.account_details.length > 0) {
    formData.value.account_details.forEach((item) => {
      let data          = {};
      data.id           = item.id;
      data.quantity     = 0;
      data.currency     = item.currency;
      data.rate         = 0.00;
      data.amount       = getAmount(item);
      data.vat          = item.vat;
      data.vat_amount   = getVatAmount(item, data.amount);
      data.total_amount = getTotalAmount(item, data.amount, data.vat_amount);
      data.description  = item.description;

      poData.general.push(data);
    })
  }
  poData.general = JSON.stringify(poData.general);

  updatePO();
}

function createPO(redirect = true) {
  createNewPO(poData, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message)
          if (redirect) navigateToListPage()
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function updatePO(redirect = true) {
  updatePOItem(poData, route.query.poId, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message);
          if (redirect) navigateToListPage()
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false;
        console.log(err)
      })
}

function navigateToListPage() {
  delete route.query.poId;
  router.push({name: `po-list`, params: route.params, query: route.query});
}

const shouldSave = computed( () => {
  if(! formData.value.status) {
    return true;
  }

  return ['pending', 'returned'].includes(formData.value.status);
})

function getConfirmLimit(data, inputValue) {
  let inputValueData = data.is_product == "0" ? parseFloat(inputValue) : parseFloat(inputValue) ;
  let csGeneralId = isPOCreate ? data.id : data.cs_general_id;
  const fetRemainingLimit = getCSAmountQuantityRemainingLimit(csGeneralId, getQuery());
  fetRemainingLimit.then(res => {
    disableSave.value = false;
    let limit = res.data;
    if((limit > data.quantity_confirm && data.is_product == "1")
        || (limit > data.rate_confirm && data.is_product == "0")
    ) {
      disableSave.value = true;
      showError("Confirm limit exceeded");
    }else{
      let availableLimit =  data.is_product == "0" ?  data.rate_confirm - limit :  data.quantity_confirm - limit;
      if (inputValueData > availableLimit){
        disableSave.value = true;
        showError("Confirm limit exceeded. Your available limit is : " + availableLimit);
      }
    }
  });
}

const itemsDetails    = computed(() => {
  return formData.value.item_details
})
const accountsDetails = computed(() => {
  return formData.value.account_details
})
const isItem          = computed(() => {
  return !!formData.value.has_item_detail
})
const discountAmount = computed(() => formData.value.adjustment_amount);
const carryingCost = computed(() => formData.value.carrying_cost);
const tdsCost = computed(() => formData.value.tds);

const subTotal        = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseFloat((item.quantity * item.rate) - item.discount_amount)
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseFloat(item.amount)
      }
    })
  }

  return subTotal.toFixed(2)
})
const totalVAT        = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.vat) {
        vat += parseFloat(item.vat_amount)
      }
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.vat) {
        vat += parseFloat((item.vat / 100) * item.amount)
      }
    })
  }

  return vat.toFixed(2)
})
const total           = computed(() => {
  return parseFloat(subTotal.value) + parseFloat(totalVAT.value)
})

watch(discountAmount, (newValue, oldValue) => {
  if (! newValue || newValue < 0) {
    adjustmentTotal.value = total.value
    formData.value.adjustment_amount = 0;
    return;
  }

  if (newValue !== oldValue) {
    adjustmentTotal.value = parseFloat(total.value) - parseFloat(discountAmount.value)
  }
})

watch(carryingCost, (newValue, oldValue) => {
  if (! newValue || newValue < 0) {
    grandTotal.value = parseFloat(adjustmentTotal.value) + parseFloat(tdsCost.value);
    formData.value.carrying_cost = 0;
    formData.value.tds = tdsCost.value;
    return;
  }

  if (newValue !== oldValue) {
    grandTotal.value = (parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value) + parseFloat(tdsCost.value) ).toFixed(2);
  }
})


watch(tdsCost, (newValue, oldValue) => {
  if (! newValue || newValue < 0) {
    grandTotal.value = parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value);
    formData.value.carrying_cost = carryingCost.value;
    formData.value.tds = 0;
    return;
  }

  if (newValue !== oldValue) {
    grandTotal.value = (parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value) + parseFloat(tdsCost.value) ).toFixed(2);
  }
})

watch(total, () => {
  adjustmentTotal.value = (parseFloat(total.value)  - parseFloat(discountAmount.value)).toFixed(2);
})

watch(adjustmentTotal, () => {
  grandTotal.value = (parseFloat(adjustmentTotal.value) + parseFloat(carryingCost.value) + parseFloat(tdsCost.value) ).toFixed(2);
})


function getAmount(data) {
  if (data.is_product == "1") {
    return data.rate * data.quantity
  } else {
    return data.amount
  }
}

function getVatAmount(data, amount) {
  if (data.vat && data.is_product == "1") {
    return (data.vat / 100 * amount).toFixed(2)
  } else {
    return data.vat / 100 * amount
  }
  return 0
}

function getTotalAmount(data, amount, vat_amount,discount_amount = 0) {
  if (data.vat == 0) {
    return parseFloat(amount) - parseFloat(discount_amount)
  }
  return parseFloat(amount) + parseFloat(vat_amount) - parseFloat(discount_amount)
}

onMounted(async () => {
  onPageReload();
})
</script>
